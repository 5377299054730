<template>
  <v-bottom-navigation
    grow
    fixed
    dark
    class="bottom-nav-bar primary--text white lighten-2"
  >
    <!-- home -->
    <v-btn :to="{ name: 'HomeApp' }" class="px-0">
      <span>{{ $t("bottom.home") }}</span>
      <v-icon>$home</v-icon>
    </v-btn>
    <!-- dashboard -->
    <v-btn @click="handleLogin('/dashboard')" class="px-0">
      <span>{{ $t("bottom.account") }}</span>
      <v-icon>$profile</v-icon>
    </v-btn>
    <!-- additional button -->
    <v-btn
      v-if="additionalBtnSrc && additionalBtnLink"
      :to="additionalBtnLink"
      class="px-0"
    >
      <v-img max-width="65px" contain :src="additionalBtnSrc"></v-img>
    </v-btn>
    <!-- fidelity -->
    <!-- coupon -->
    <v-dialog v-model="fidelityDialog" v-if="isAuthenticated">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="px-0">
          <span>{{ $t("bottom.fidelity") }}</span>
          <v-icon>$fidelity</v-icon>
        </v-btn>
      </template>
      <div
        class="d-flex flex-column fidelity-dialog"
        v-if="fidelityDialog && isAuthenticated"
      >
        <v-btn
          @click="closeFidelityDialog()"
          icon
          small
          class="vuedl-layout__closeBtn close"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <FidelityCardRow :toggleCard="true" />
      </div>
      <div
        class="d-flex flex-column fidelity-dialog"
        v-else-if="!isAuthenticated"
      >
        <Login />
      </div>
    </v-dialog>
    <v-btn @click="handleDialog()" v-if="!isAuthenticated" class="px-0">
      <span>{{ $t("bottom.fidelity") }}</span>
      <v-icon>$fidelity</v-icon>
    </v-btn>
    <!-- prodotti -->
    <v-btn @click.prevent.stop="drawerLeft = !drawerLeft" exact class="px-0">
      <span>{{ $t("bottom.products") }}</span>
      <v-icon>$menu</v-icon>
    </v-btn>
    <!-- carrello -->
    <v-btn @click="handleLogin('/cart')" class="pa-0">
      <v-badge
        :content="totalItems"
        :value="totalItems > 0"
        :key="totalItems"
        transition="bounce"
        color="primary default--text font-weight-bold"
        overlap
        offset-x="20"
        class="d-flex flex-column"
      >
        <v-icon>$cart</v-icon>
        <span>{{ $t("bottom.cart") }}</span>
      </v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import FidelityCardRow from "@/components/categoryBlock/FidelityCardRow.vue";

import login from "~/mixins/login";

import get from "lodash/get";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  mixins: [login],
  props: {
    applicationConfig: { type: Object, required: false }
  },
  components: { FidelityCardRow },
  data() {
    return { fidelityDialog: false, oldBrightness: null };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    siteBtnClicked() {
      if (this.site == "site") {
        this.drawerLeft = !this.drawerLeft;
      } else {
        this.$router.push({ name: "HomeSite" });
      }
    },
    async handleLogin(path) {
      if (this.isAuthenticated) {
        this.$router.push(path);
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.$router.push(path);
        }
      }
    },
    async handleDialog() {
      if (this.isAuthenticated) {
        this.fidelityDialog = true;
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.fidelityDialog = true;
        }
      }
    },
    closeFidelityDialog() {
      // if (this.isCordova && window.cordova) {
      //   // eslint-disable-next-line no-undef
      //   cordova.plugins.brightness.setBrightness(_this2.oldBrightness);
      // }
      this.fidelityDialog = false;
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      hasFavorites: "cart/hasFavorites"
    }),
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.storeDrawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    totalItems() {
      return this.cart?.cartItems?.length || 0;
      // let total = 0;
      // forEachCartItem(this.cart, function(item) {
      //   total += item.quantity > 0 ? item.quantity : 1;
      // });
      // return total;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    additionalBtnLink() {
      return get(
        this.applicationConfig,
        "metaData.template_model.ADDITIONAL_BTN_LINK"
      );
    },
    additionalBtnSrc() {
      return get(
        this.applicationConfig,
        "metaData.template_model.ADDITIONAL_BTN_IMG"
      );
    }
  },
  watch: {
    fidelityDialog(newvalue, oldvalue) {
      var _this2 = this;
      if (newvalue != oldvalue && newvalue == false) {
        if (_this2.isCordova && window.cordova) {
          // eslint-disable-next-line no-undef
          cordova.plugins.brightness.setBrightness(_this2.oldBrightness);
        }
      } else if (newvalue != oldvalue && newvalue == true) {
        if (this.isCordova && window.cordova) {
          // eslint-disable-next-line no-undef
          cordova.plugins.brightness.getBrightness(
            function(data) {
              _this2.oldBrightness = data;
            },
            function(err) {
              console.log("error: " + err);
            }
          );
        }
      }
    }
  }
};
</script>
<style lang="scss">
.v-main {
  padding-bottom: calc(160px + env(safe-area-inset-top)) !important;
}
.keyboard-open {
  .v-main {
    padding-bottom: calc(80px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios {
  .v-main {
    padding-bottom: calc(210px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios.keyboard-open {
  .v-main {
    padding-bottom: calc(110px + env(safe-area-inset-top)) !important;
  }
}

.bottom-nav-bar {
  height: $bottom-navigation-bar-height;
  // color: $primary !important;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top) + 20px
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  box-shadow: 0px 2px 21px #0000004d !important;
  border-radius: 15px 15px 0px 0px;
  opacity: 1;
  .v-btn {
    min-width: auto !important;
    padding: 0 5px;
    font-size: 0.9rem !important;
    .v-btn__content {
      span {
        color: var(--v-default-base) !important;
        font-size: 9px;
        letter-spacing: 0px;
        font-weight: normal;
        padding-top: 4px;
      }
      .v-icon {
        color: var(--v-default-base) !important;
        font-size: 27px;
      }
    }
  }
}
.platform-ios {
  .bottom-nav-bar {
    //height: $bottom-navigation-bar-height-ios !important;
    position: fixed;
    bottom: 0;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    max-height: calc(
      #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) + 20px
    ) !important;
    padding-top: calc(env(safe-area-inset-top));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(110px + env(safe-area-inset-bottom));
    .v-icon {
      font-size: 32px !important;
    }
  }
}

.keyboard-open {
  .bottom-nav-bar {
    display: none !important;
  }
}

.fidelity-dialog {
  position: relative;
  margin-top: 20px;
  .close {
    top: -30px;
    right: 0px;
    .v-icon {
      font-size: 30px;
    }
  }
}
</style>
